import { getMASLPlayerList } from 'utils/api/masl-api'
import { useApi } from 'utils/hooks/useApi'
import { type MASLPlayerInfo, MASL_PLAYERS_SESSION_KEY } from 'utils/constants/masl'
import { getSession, setSession } from 'utils/sessionStorage'

export const useMASLPlayers = (ignoreLocal = false) => {
  const { data, error, loading } = useApi(async () => {
    if (!ignoreLocal) {
      const storedPlayers = getSession<MASLPlayerInfo[]>(MASL_PLAYERS_SESSION_KEY)
      if (storedPlayers) {
        return { players: storedPlayers, updatedTime: 0 }
      }
    }
    const response = await getMASLPlayerList()
    setSession(MASL_PLAYERS_SESSION_KEY, response.players)
    return response
  }, [ignoreLocal])

  return { data, error, loading }
}
