import { LoadingButton } from '@mui/lab'
import { Divider, Input, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'

import { type League } from 'utils/constants/constants'
import { MASL_PLAYERS_SESSION_KEY, type MASLPlayerInfo } from 'utils/constants/masl'
import { useAuthState } from 'utils/hooks/useAuthState'
import { readFile } from 'utils/csv'
import { CSVSupportLink, Section } from 'components/common'
import LoadingDialog from 'components/common/modal/LoadingDialog'
import { setMASLPlayerList } from 'utils/api/masl-api'
import { removeSession } from 'utils/sessionStorage'
import { useRedirectToHome } from 'utils/hooks/useRedirectToHome'
import { useMASLPlayers } from 'utils/hooks/masl/useMASLPlayers'

const HEADER_ROW: Array<keyof MASLPlayerInfo> = [
  'playerName',
  'division',
  'team',
  'number',
  'position',
  'rank',
  'status',
  'email',
  'birthDate',
  'nickname'
]

interface MASLUploadPlayerListProps {
  league: League
}

export const MASLUploadPlayerList: React.FC<MASLUploadPlayerListProps> = ({ league }) => {
  const { user } = useAuthState()
  const redirect = useRedirectToHome()
  const { data, loading: apiLoading } = useMASLPlayers(true)

  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState<string>()
  const inputRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)

  async function handleParseClick () {
    setLoading(true)
    const file = inputRef.current?.files?.[0]

    if (!file || !user?.id) {
      setError(true)
      setErrorText('Missing input file.')
      return
    } else {
      setError(false)
    }

    const { results: players, errors } = await readFile<MASLPlayerInfo>(file, HEADER_ROW)

    if (errors.length > 0) {
      setError(true)
      setErrorText(errors.toString())
    } else {
      setError(false)
    }

    await setMASLPlayerList(league, players)
    removeSession(MASL_PLAYERS_SESSION_KEY)
    setLoading(false)
    await redirect()
  }

  return (
    <>
      <section>
        <section>
          <Typography variant='h5'>
            Your <CSVSupportLink /> must have columns titled:<br/>
          </Typography>
          {!apiLoading && <Typography variant='caption'>
            Last Updated: {data?.updatedTime ? new Date(data.updatedTime).toLocaleString() : 'Never'}
          </Typography>}
          <table style={{ border: '1px solid darkgray', borderCollapse: 'collapse', marginTop: 16 }}>
            <tbody>
              <tr>
                {HEADER_ROW.map((value, index) => <td key={index} style={{ border: '1px solid darkgray', padding: 4 }}>{value}</td>)}
              </tr>
            </tbody>
          </table>
        </section>

        <Section style={{ marginTop: 16 }}>
          <Input
            inputRef={inputRef}
            type='file'
            placeholder='Schedule.csv'
            required
            error={error}
          />
          <Divider orientation='vertical' flexItem />
          <LoadingButton variant='contained' onClick={() => { void handleParseClick() }}>Import CSV</LoadingButton>
        </Section>
        <Typography style={{ color: 'red' }}>{error && errorText}</Typography>
      </section>
      <LoadingDialog open={loading} body='Uploading players...' />
    </>
  )
}
